<script lang="ts">
  import { page } from '$app/stores';
  import Icon from '$lib/components/icon/icon.svelte';
  import type { MenuLink } from '$lib/contentful/models/menu-link';
  import type { MenuLinkGroup } from '$lib/contentful/models/menu-link-group';

  export let link: MenuLink | MenuLinkGroup;
  export let closeNav: (() => void) | undefined;

  $: path = $page.url.pathname;
  let subNavOpen = false;

  const toggleSubNav = () => (subNavOpen = !subNavOpen);
</script>

{#if link.contentType === 'footerLink'}
  <li class="nav-link">
    <a
      class:active={path.startsWith(link.link)}
      href={link.link}
      on:click={() => closeNav && closeNav()}
      target={link.link.startsWith('/') ? null : '_blank'}>{link.linkText}</a
    >
  </li>
{:else if link.contentType === 'footerLinkGroup'}
  <li class="nav-link-group-xl group">
    {link.title}
    <ul class="sub-nav">
      {#each link.links as subLink}
        <li class="nav-link">
          <a
            class:active={path.startsWith(subLink.link)}
            href={subLink.link}
            target={subLink.link.startsWith('/') ? null : '_blank'}
            >{subLink.linkText}</a
          >
        </li>
      {/each}
    </ul>
  </li>
  <li class="nav-link-group-max-xl">
    <button class="sub-nav-trigger" on:click={toggleSubNav}>
      {link.title}
      <Icon width={20} height={20} name={subNavOpen ? 'hyphen' : 'add'} />
    </button>
    {#if subNavOpen}
      <ul class="sub-nav">
        {#each link.links as subLink}
          <li class="nav-link">
            <a
              class:active={path.startsWith(subLink.link)}
              href={subLink.link}
              on:click={() => closeNav && closeNav()}
              target={subLink.link.startsWith('/') ? null : '_blank'}
              >{subLink.linkText}</a
            >
          </li>
        {/each}
      </ul>
    {/if}
  </li>
{/if}

<style lang="postcss">
  .nav-link {
    @apply h-full whitespace-nowrap flex items-center max-xl:items-start max-xl:text-left;
  }

  .nav-link a {
    @apply h-full flex items-center border-b-2 border-transparent transition-colors focus:outline-none focus:text-indigo-200 focus:border-indigo-200 hover:text-indigo-200;

    &.active {
      @apply border-green-300 text-green-300 focus:text-indigo-200 focus:border-indigo-200;
    }
  }

  .nav-link-group-xl {
    @apply cursor-default relative max-xl:hidden border-b-2 border-transparent focus-within:text-indigo-200;
  }

  .nav-link-group-max-xl {
    @apply xl:hidden w-full border-b-2 border-transparent;
  }

  .sub-nav-trigger {
    @apply hover:text-indigo-200 w-full flex py-2 items-center justify-between border-b-2 border-transparent focus:outline-none focus-visible:border-indigo-200 focus-visible:text-indigo-200;
  }

  .sub-nav {
    @apply max-xl:ml-4 max-xl:mt-4 xl:sr-only xl:group-focus-within:not-sr-only xl:group-hover:not-sr-only flex flex-col gap-3 bg-space-black text-white xl:!p-8 xl:!absolute xl:rounded-b-lg xl:drop-shadow-lg xl:transform-gpu xl:z-50;
  }
</style>
