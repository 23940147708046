<script lang="ts">
  import Checkbox from '@temporalio/ui/holocene/checkbox.svelte';
  import Input from '@temporalio/ui/holocene/input/input.svelte';
  import Combobox from '@temporalio/ui/holocene/combobox/combobox.svelte';

  import Text from '../text/text.svelte';

  import {
    isMarketoCheckbox,
    isMarketoCombobox,
    isMarketoHidden,
    isMarketoPicklist,
    isMarketoText,
    isMarketoTextInput,
    type MarketoField,
    type MarketoSelect,
  } from './marketo-form-metadata';

  import { formatLabel } from './format-label';
  import { stripHtml } from 'string-strip-html';

  export let field: MarketoField;
  export let Id: number;
  export let onChange: (field: string, value: string) => void;
  export let revealFields: Record<string, MarketoSelect> = {};

  $: isRevealed = revealFields[field.Name] !== undefined;
  $: options = isRevealed
    ? revealFields[field.Name]?.PicklistValues
    : isMarketoPicklist(field)
    ? field.PicklistValues
    : [];
  $: value = !isRevealed ? '' : options?.find((v) => v.isDefault)?.value || '';

  const onComboboxChange = (event: CustomEvent) => {
    onChange(field.Name, event.detail?.value?.value);
  };
</script>

{#if isMarketoTextInput(field)}
  <Input
    type={field.DataType === 'email' ? 'email' : 'text'}
    id={String(Id)}
    label={formatLabel(field.InputLabel)}
    name={field.Name}
    required={field.IsRequired}
    value={field.InputInitialValue}
  />
{/if}
{#if isMarketoCheckbox(field)}
  <Checkbox
    id={String(Id)}
    label={formatLabel(field.InputLabel)}
    name={field.Name}
    required={field.IsRequired}
    value={Boolean(field.InputInitialValue)}
  />
{/if}
{#key isRevealed}
  {#if isMarketoCombobox(field, isRevealed)}
    <Combobox
      id={String(Id)}
      label={formatLabel(field.InputLabel)}
      name={field.Name}
      required={field.IsRequired}
      {value}
      noResultsText="No results"
      optionValueKey="value"
      optionLabelKey="label"
      {options}
      on:change={onComboboxChange}
    />
  {/if}
{/key}
{#if isMarketoText(field)}
  <Text variant="body-16">{stripHtml(field.Htmltext).result}</Text>
{/if}
{#if isMarketoHidden(field)}
  <input type="hidden" name={field.Name} value={field.InputInitialValue} />
{/if}
