<script lang="ts">
  import { onDestroy, onMount } from 'svelte';

  import { page } from '$app/stores';

  import {
    filterResources,
    appliedFiltersStores,
    createStore,
    serializeFilters,
    parseFilters,
    group,
  } from '$lib/stores/filters';
  import type { FilterableResources } from '$lib/contentful/models/filterable-resources';
  import type { Resource } from '$lib/contentful/models/resource';
  import Box from '$lib/layout/grid/box.svelte';
  import Text from '$lib/components/text/text.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Filters from '$lib/components/filters/filters.svelte';
  import ResourceCard from './resource-card.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import MobileFilters from '../filters/mobile-filters.svelte';
  import Icon from '../icon/icon.svelte';
  import { goto } from '$app/navigation';
  import type { Unsubscriber } from 'svelte/store';
  import { generatePageNumbers } from '$lib/utilities/generate-page-numbers';
  import RichText from '../rich-text/rich-text.svelte';
  import Button from '../button/button.svelte';

  const PAGE_SIZE = 15;

  export let content: FilterableResources;

  $: ({
    heading,
    filters,
    resources,
    background,
    display,
    anchor,
    entityId,
    eyebrow,
    body,
    callToAction,
  } = content);

  let currentPage = 1;
  let pageIndex = 0;
  let pageNumbers: (number | undefined)[] = [];
  let filteredResources: Resource[] = resources ?? [];
  let paginatedResources: Resource[][] = [[]];
  let pageUnsubscriber: Unsubscriber;
  let filtersUnsubscriber: Unsubscriber;

  onMount(() => {
    appliedFiltersStores[anchor] = createStore($page.url.searchParams);

    filtersUnsubscriber = appliedFiltersStores[anchor].subscribe((filters) => {
      const serialized = serializeFilters(filters);
      goToFilters(serialized);
    });

    pageUnsubscriber = page.subscribe(({ url }) => {
      currentPage = parseInt(url.searchParams.get('page') ?? '1');
      pageIndex = currentPage - 1;
      updateResources(url);
    });
  });

  onDestroy(() => {
    if (filtersUnsubscriber !== undefined) filtersUnsubscriber();
    if (pageUnsubscriber !== undefined) pageUnsubscriber();
  });

  const updateResources = (url: URL) => {
    const parsedFilters = parseFilters(url.searchParams);
    filteredResources = filterResources(parsedFilters, resources);
    paginatedResources = group(filteredResources, PAGE_SIZE);
  };

  const goToFilters = (filters: URLSearchParams) => {
    const url = new URL($page.url);

    if (filters.size === 0) {
      goto(url.pathname, { noScroll: true });
    } else {
      url.hash = anchor;
      url.search = filters.toString();
      url.searchParams.delete('page');
      goto(url);
    }
  };

  const goToPage = (pageNumber: number | undefined) => {
    if (!pageNumber || pageNumber === currentPage) return;
    const url = new URL($page.url);
    url.hash = anchor;
    const params = new URLSearchParams(url.search);
    params.set('page', pageNumber.toString());
    url.search = params.toString();
    goto(url);
  };

  $: pageNumbers = generatePageNumbers(paginatedResources.length, currentPage);
</script>

<Grid as="div" layout="columns" {background} id={anchor} class="gap-y-8">
  <Box
    as="div"
    class="flex max-xl:flex-col gap-4 max-xl:items-start xl:flex-row justify-between items-center pb-8"
  >
    <div class="flex flex-col gap-4 xl:w-2/3">
      {#if eyebrow}
        <Text class="text-mint" variant="eyebrow-16" as="p">{eyebrow}</Text>
      {/if}
      <Text fieldId="heading" {entityId} as="h3" variant="display-68">
        {heading}
      </Text>
      {#if body}
        <RichText text={body} theme="dark" />
      {/if}
      {#if callToAction && filters.length > 0}
        {@const { text, ...rest } = callToAction}
        <Button {...rest}>{text}</Button>
      {/if}
    </div>
    <div>
      {#if callToAction && !filters.length}
        {@const { text, ...rest } = callToAction}
        <Button {...rest}>{text}</Button>
      {/if}

      {#if filters.length > 0}
        <Filters
          {entityId}
          {anchor}
          {filters}
          appliedFilters={appliedFiltersStores[anchor]}
        />
        <MobileFilters
          {entityId}
          {anchor}
          {filters}
          appliedFilters={appliedFiltersStores[anchor]}
        />
      {/if}
    </div>
  </Box>

  {#if display === 'cards'}
    <Box
      as="div"
      class="grid max-md:grid-cols-1 max-lg:grid-cols-2 lg:grid-cols-3 gap-5"
    >
      {#if paginatedResources[pageIndex]}
        {#each paginatedResources[pageIndex] as resource}
          <ResourceCard {resource} />
        {/each}
      {:else}
        <Text class="col-start-2 text-center" as="p" variant="body-32"
          >Nothing matches your current filters</Text
        >
      {/if}
    </Box>
    <Box as="div">
      <div
        class="flex max-lg:flex-col max-lg:items-center gap-4 lg:flex-row lg:justify-between"
      >
        {#if paginatedResources.length > 1}
          <button
            aria-label="Go to previous page"
            class="pagination-button"
            disabled={!paginatedResources[pageIndex - 1]}
            on:click={() => goToPage(currentPage - 1)}
          >
            <Icon name="arrow-left" />
            prev
          </button>
        {/if}
        <div class="max-sm:hidden sm:flex sm:flex-row gap-2">
          {#each pageNumbers as pageNumber}
            <button
              class="pagination-button"
              class:current={pageNumber === currentPage}
              disabled={!pageNumber}
              on:click={() => goToPage(pageNumber)}
              >{pageNumber ?? '...'}</button
            >
          {/each}
        </div>
        {#if paginatedResources.length > 1}
          <button
            aria-label="Go to next page"
            class="pagination-button"
            disabled={!paginatedResources[pageIndex + 1]}
            on:click={() => goToPage(currentPage + 1)}
          >
            next
            <Icon name="arrow-right" />
          </button>
        {/if}
      </div>
    </Box>
  {:else if display === 'logos'}
    <Box as="div">
      <div class="logo-grid">
        {#each filteredResources as { customerLogo }}
          {#if customerLogo}
            <div class="logo">
              <ContentfulImage
                {entityId}
                fieldId="customerLogo"
                src={customerLogo.url}
                alt={customerLogo.description}
                height={50}
                fitStrategy="fill"
              />
            </div>
          {/if}
        {:else}
          <Text as="p" variant="body-32"
            >Nothing matches your current filters</Text
          >
        {/each}
      </div>
    </Box>
  {/if}
</Grid>

<style lang="postcss">
  .logo-grid {
    @apply grid sm:grid-cols-3 max-sm:grid-cols-2;

    .logo {
      @apply border-graphite border max-lg:py-8 lg:py-16 sm:px-4 lg:px-12 xl:px-24 flex items-center justify-center;
    }
  }

  .pagination-button {
    @apply bg-deeper-blue text-blue-gray-800 uppercase px-6 py-4 rounded-lg flex gap-4 enabled:hover:text-blue-gray-100 enabled:hover:ring-4 enabled:hover:ring-blue-gray-500/50 disabled:bg-deeper-blue/50 disabled:text-blue-gray-600;

    &.current {
      @apply ring-2 ring-ultraviolet text-ultraviolet;
    }
  }
</style>
