<script lang="ts">
  import { fly } from 'svelte/transition';

  import Button from '$lib/components/button/button.svelte';
  import NavigationLink from './navigation-link.svelte';
  import type { MenuLink } from '$lib/contentful/models/menu-link';
  import type { MenuLinkGroup } from '$lib/contentful/models/menu-link-group';
  import type { CallToAction } from '$lib/contentful/models/call-to-action';
  import Icon from '../icon/icon.svelte';

  let mobileNavOpen = false;

  const openNav = () => (mobileNavOpen = true);
  const closeNav = () => (mobileNavOpen = false);

  export let links: (MenuLinkGroup | MenuLink)[];
  export let callsToAction: CallToAction[];
</script>

<nav class="site-nav site-nav-lg">
  <a href="/" aria-label="Home">
    <img
      src="/images/logos/logo-temporal-with-copy-white-text.svg"
      alt="Temporal Logo"
    />
  </a>
  <span class="xl:hidden">
    <Button
      leadingIcon="nav"
      variant="ghost"
      theme="off-white"
      on:click={openNav}
    />
  </span>
  <ul class="links links-lg">
    {#each links as link}
      <NavigationLink {link} {closeNav} />
    {/each}
    <a class="gh-link" href="https://github.com/temporalio" target="_blank">
      <Icon name="github" />
    </a>
    <div class="flex items-center gap-2">
      {#each callsToAction as { text, ...rest }}
        <Button {...rest}>{text}</Button>
      {/each}
    </div>
  </ul>
</nav>

{#if mobileNavOpen}
  <aside
    class="site-nav site-nav-sm"
    transition:fly={{ duration: 500, x: 200 }}
  >
    <nav class="w-full flex flex-col gap-10">
      <div class="flex flex-row justify-between items-center w-full">
        <a href="/" aria-label="Home" on:click={closeNav}>
          <img
            src="/images/logos/logo-temporal-with-copy-white-text.svg"
            alt="Temporal Logo"
          />
        </a>
        <Button
          leadingIcon="close"
          variant="ghost"
          theme="off-white"
          on:click={closeNav}
        />
      </div>
      <ul class="links links-sm">
        <div class="flex gap-6 items-center">
          <a
            class="gh-link"
            href="https://github.com/temporalio"
            target="_blank"
          >
            <Icon name="github" />
          </a>
          {#each callsToAction as { text, ...rest }}
            <Button {...rest} on:click={closeNav}>{text}</Button>
          {/each}
        </div>
        {#each links as link}
          <NavigationLink {link} {closeNav} />
        {/each}
      </ul>
    </nav>
  </aside>
{/if}

<style lang="postcss">
  .site-nav {
    @apply w-full flex bg-space-black overflow-auto xl:overflow-visible z-50 top-0;
  }

  .site-nav-lg {
    @apply h-[var(--site-nav-height)] px-10 max-xl:pr-4 items-center gap-4 justify-between sticky;
  }

  .site-nav-sm {
    @apply max-w-screen-sm h-full p-4 pl-10 flex-col items-end fixed right-0 xl:hidden;
  }

  .links {
    @apply h-full flex gap-6 font-sans text-base font-medium;
  }

  .links-lg {
    @apply flex-row items-center max-xl:gap-4 max-xl:text-sm max-xl:hidden;
  }

  .links-sm {
    @apply w-full flex-col;
  }

  .gh-link {
    @apply text-off-white hover:text-indigo-200;
  }
</style>
