<script lang="ts">
  import { twMerge } from 'tailwind-merge';

  import Button from '../button/button.svelte';
  import Icon from '../icon/icon.svelte';
  import {
    isMarketoPicklist,
    type MarketFormMetadata,
    type MarketoSelect,
  } from './marketo-form-metadata';
  import Field from './marketo-field.svelte';

  export let metadata: MarketFormMetadata;
  export let loading = false;

  let classes = 'max-w-3xl mx-auto';
  export { classes as class };

  let revealFields: Record<string, MarketoSelect> = {};

  const onChange = (field: string, value: string) => {
    const fields = metadata.rows.flat();
    const relatedField = fields.find((f) =>
      f.VisibilityRule?.rules?.find((rule) => rule?.subjectField === field),
    );
    if (relatedField && isMarketoPicklist(relatedField)) {
      let newRevealedFields = { ...revealFields };
      delete newRevealedFields[relatedField.Name];
      const rule = relatedField?.VisibilityRule?.rules.find((rule) =>
        rule.values.includes(value),
      );
      if (rule) {
        newRevealedFields[relatedField.Name] = {
          ...relatedField,
          PicklistValues: rule?.picklistFilterValues,
        };
      }
      revealFields = newRevealedFields;
    }
  };
</script>

<div class={twMerge('container', classes)}>
  <form class="flex flex-col gap-4" on:submit>
    {#each metadata.rows as row}
      <div class="flex items-center gap-4">
        {#each row as field, Id}
          <div class="flex-1">
            <Field {field} {Id} {onChange} {revealFields} />
          </div>
        {/each}
      </div>
    {/each}
    <div class="flex items-center justify-start">
      <Button type="submit">
        {#if loading}<Icon name="spinner" class="animate-spin" />{/if}
        {metadata.SubmitLabel}
      </Button>
    </div>
  </form>
</div>
