<script lang="ts">
  import Icon from '$lib/components/icon/icon.svelte';
  import Text from '$lib/components/text/text.svelte';
  import type { PageNavigationLink } from '$lib/contentful/models/page-navigation-link';

  type $$Props = {
    link: PageNavigationLink;
  };

  export let link: PageNavigationLink;

  $: ({ href, linkText, icon, entityId, description } = link);
  $: target =
    href.startsWith('http://') || href.startsWith('https://')
      ? '_blank'
      : '_self';
</script>

<a class="page-navigation-link group" {target} {href} aria-label={linkText}>
  {#if icon}
    <div class="py-1 group-hover:text-white text-indigo-100 transition-colors">
      <Icon name={icon} />
    </div>
  {/if}
  <div class="flex flex-col gap-0 md:gap-2 justify-between">
    <Text variant="heading-24" {entityId} fieldId="linkText">
      <slot name="link-text">{linkText}</slot>
    </Text>
    {#if description}
      <Text
        variant="body-16"
        class="w-full group-hover:text-white text-indigo-100 transition-colors"
        {entityId}
        fieldId="description"
      >
        <slot name="description">{description}</slot>
      </Text>
    {/if}
  </div>
</a>

<style lang="postcss">
  .page-navigation-link {
    @apply flex flex-row items-start gap-2;
  }
</style>
