<script lang="ts">
  import type { FAQ } from '$lib/contentful/models/faq';
  import Text from '$lib/components/text/text.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import FaqItem from './faq-item.svelte';
  import type { Background } from '$lib/contentful/models/background';

  export let content: FAQ;
  export let background: Background = 'deep-blue';
  $: ({ title, entityId, questionsAndAnswers } = content);
</script>

<Grid as="div" layout="columns" {background}>
  <Box as="div" class="flex flex-col items-center gap-8">
    <Text {entityId} fieldId="title" variant="display-68">{title}</Text>
    <div class="w-full">
      {#each questionsAndAnswers as { question, answer, entityId }}
        <FaqItem {question} {answer} {entityId} />
      {/each}
    </div>
  </Box>
</Grid>
