import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95')
];

export const server_loads = [7,10,13,12,14,15,16,18,19,17,20,21,22,23,6,3,24,25,26,28,29,30,31,32,33,34,4,36,35,37,9];

export const dictionary = {
		"/(site)": [53,[7],[8]],
		"/(site)/about": [55,[7,10],[8]],
		"/(site)/blog": [~56,[7],[8,11]],
		"/(site)/blog/categories/[category]": [58,[7,,13],[8,11]],
		"/(site)/blog/[slug]": [57,[7,,12],[8,11]],
		"/(site)/brand": [59,[7,14],[8]],
		"/(site)/careers": [60,[7,15],[8]],
		"/(site)/change-log": [61,[7,16],[8]],
		"/(site)/change-log/product-area/[area]": [63,[7,16,18],[8]],
		"/(site)/change-log/type/[type]": [64,[7,16,19],[8]],
		"/(site)/change-log/[slug]": [62,[7,16,17],[8]],
		"/(site)/cloud": [65,[7,20],[8]],
		"/(site)/code-exchange": [~66,[7],[8]],
		"/(site)/code-exchange/[slug]": [67,[7,21],[8]],
		"/(site)/community": [68,[7,22],[8]],
		"/(site)/events/aws-reinvent-2024": [70,[7],[8]],
		"/(site)/events/[slug]": [69,[7,23],[8]],
		"/(sign-up)/get-cloud": [46,[,6],[5]],
		"/(site)/get-cloud/aws-marketplace": [~71,[7],[8]],
		"/(site)/get-cloud/aws-test": [~72,[7],[8]],
		"/(sign-up)/get-cloud/callback": [47],
		"/(sign-up)/get-cloud/contact-information": [48,[,6],[5]],
		"/(sign-up)/get-cloud/log-in": [49,[,6],[5]],
		"/(sign-up)/get-cloud/payment-information": [50,[,6],[5]],
		"/(sign-up)/get-cloud/provisioning": [51,[,6],[5]],
		"/(sign-up)/get-cloud/waiting": [52,[,6],[5]],
		"/(home)/home": [38,[2,3]],
		"/(site)/how-it-works": [73,[7,24],[8]],
		"/(site)/in-use": [74,[7,25],[8]],
		"/(site)/in-use/[slug]": [~75,[7,25],[8]],
		"/(site)/news": [76,[7,26],[8,27]],
		"/(site)/news/[slug]": [77,[7,26,28],[8,27]],
		"/(site)/partners": [78,[7,29],[8]],
		"/(site)/preview/[entityId]": [79,[7,30,31],[8]],
		"/(site)/pricing": [80,[7],[8]],
		"/(site)/product": [81,[7,32],[8]],
		"/(site)/resources": [82,[7,33],[8]],
		"/(site)/resources/case-studies": [~83,[7,33],[8]],
		"/(site)/resources/case-studies/[slug]": [~84,[7,33],[8]],
		"/(site)/resources/longform": [~85,[7,33],[8]],
		"/(site)/resources/longform/[slug]": [~86,[7,33],[8]],
		"/(site)/resources/on-demand": [~87,[7,33],[8]],
		"/(site)/resources/on-demand/replay-2023": [~89,[7,33],[8]],
		"/(site)/resources/on-demand/replay-2024": [~90,[7,33],[8]],
		"/(site)/resources/on-demand/[slug]": [~88,[7,33],[8]],
		"/(site)/security": [91,[7,34],[8]],
		"/(set-up)/setup": [~39,[4]],
		"/(set-up)/setup/all-done": [40,[4]],
		"/(set-up)/setup/choose-an-sdk": [41,[4]],
		"/(set-up)/setup/install-temporal-cli": [42,[4]],
		"/(set-up)/setup/open-the-web-ui": [43,[4]],
		"/(set-up)/setup/run-a-sample-workflow": [44,[4]],
		"/(set-up)/setup/start-development-server": [45,[4]],
		"/(site)/startup-program-aws-for-startups": [93,[7,36],[8]],
		"/(site)/startup": [92,[7,35],[8]],
		"/(site)/temporal-versus": [~94,[7],[8]],
		"/(site)/temporal-versus/cadence": [95,[7,37],[8]],
		"/(site)/[...slug]": [54,[7,9],[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';